<template>
    <PageBlock
        v-if="pageblock"
        title="Price"
        :loading="loading"
        can-fullscreen
        v-observe-visibility="chart_became_visible"
        @toggle-fullscreen="resize_chart_fullscreen"
        @toggle-collapsed="resize_chart_collapse">
        <div v-loading="loading" :class="'light-chart ' + class_name" v-show="!collapsed"></div>
    </PageBlock>
    <div
        v-else
        v-observe-visibility="chart_became_visible"
    >
        <div v-loading="loading" :class="'light-chart ' + class_name"></div>
    </div>
</template>

<script>
import moment from 'moment'
import { createChart } from 'lightweight-charts'
import PageBlock from '@/components/PageBlock'
import themed_chart from '@/mixins/themed_chart'

export default {
    name: 'isin_price_chart',
    components: { PageBlock },
    mixins: [themed_chart],

    props: {
        class_name: {
            default: ''
        },
        isin: {
            required: true,
        },
        asset: {
            required: true,
        },
        period_from: {
            required: true,
        },
        period_to: {
            required: true,
        },
        use_markers: {
            type: Boolean,
            default: false
        },
        operations: {
            required: false
        },
        pageblock: {
            type: Boolean,
            default: true
        },
    },

    data(){
        return {
            fullscreen : false,
            collapsed  : false,
            loading    : false,
            chart      : null,
            width      : '400',
            height     : '350',
            markers    : [],
            series     : null,
            data       : [],
        }
    },

    computed: {
        options(){
            return {
                layout: {
                    backgroundColor: this.line_chart_settings[this.current_theme].bg_colors[0],
                    textColor: this.line_chart_settings[this.current_theme].label_color,
                },
                grid: {
                    horzLines: {
                        color: this.line_chart_settings[this.current_theme].bg_splitter,
                    },
                    vertLines: {
                        color: this.line_chart_settings[this.current_theme].bg_splitter,
                    },
                },
                priceScale: {
                    borderColor: this.line_chart_settings[this.current_theme].axis_color,
                },
                timeScale: {
                    timeVisible: false,
                    fixLeftEdge: true,
                    fixRightEdge: true,
                    borderColor: this.line_chart_settings[this.current_theme].axis_color,
                },
                localization: {
                    locale: 'en-US',
                },
            }
        },
        line_options(){
            return {
                color: this.line_chart_settings[this.current_theme].line_color
            }
        },
        chart_class_name(){ return this.class_name ? this.class_name : 'light-chart' }
    },

    methods: {
        toggle_fullscreen(){
            this.$fullscreen.toggle(this.$el, {
                teleport: true,
                pageOnly: true,
                callback: (isFullscreen) => {
                    this.fullscreen = isFullscreen
                    this.collapsed = false
                    this.resize_chart()
                },
            })
        },

        toggle_collapse(){
            this.collapsed = !this.collapsed
        },

        resize_chart(fullscreen = false) {
            let width_reducer = 20
            if(fullscreen) width_reducer = 100

            let height_reducer = 0
            if(fullscreen) height_reducer = 130

            let height = this.height
            if(fullscreen) height = this.$el.clientHeight - height_reducer

            let width = this.$el.clientWidth - width_reducer

            if (this.chart) {
                this.chart.resize(width, height);
                this.chart.timeScale().fitContent();
            }
        },

        resize_chart_fullscreen(is_fullscreen){
            this.resize_chart(is_fullscreen)
        },

        resize_chart_collapse(collapsed){
            if(!collapsed) {
                this.chart = null
                this.$nextTick(() => this.make_chart())
            }
        },

        chart_became_visible(){
            this.resize_chart()
        },

        make_chart(){
            if (this.chart){
                this.chart.removeSeries(this.series)
            }
            else {
                this.chart = createChart(this.$el.querySelector(`.${this.chart_class_name}`), this.options);
            }

            this.series = this.chart.addLineSeries(this.line_options);

            this.resize_chart();
            this.series.setData(this.data)
            this.apply_markers()
        },

        get_list(){
            this.loading = true
            this.$store.dispatch('instrument/getInstrumentPrices', {
                isin      : this.isin,
                asset     : this.asset,
                date_from : this.period_from,
                date_to   : this.period_to,
            }).then((response) => {

                let sorted = response.sort((a, b) => {
                    let at = new Date(a.time)
                    let bt = new Date(b.time)
                    if (at < bt) {
                        return -1;
                    } else if (at == bt) {
                        return 0;
                    } else {
                        return 1;
                    }
                })

                let filtered = []

                let seen = new Set();

                filtered = sorted.filter(el => {
                    const duplicate = seen.has(el.time);
                    seen.add(el.time);
                    return !duplicate;
                });

                this.data = filtered

                this.make_chart()
                this.loading = false
            });
        },

        get_operation_type(type){
            let types = {
                buy: 'B',
                sell: 'S',
                sell_short: 'SSh',
                buy_short: 'BSh'
            }

            return types[type]
        },

        apply_markers(){
            if(this.operations && this.use_markers && this.series){
                this.markers = []

                this.operations.forEach((operation, index) => {
                    if(!this.get_operation_type(operation.t)) return

                    let time = moment(operation.tdt.$date)

                    let marker = {
                        time: time.format('YYYY-MM-DD'),
                        shape: 'arrowDown',
                        color: 'black',
                        text: this.get_operation_type(operation.t),
                        position: 'aboveBar',
                        id: index,
                        size: 0.5
                    }
                    this.markers.push(marker)
                })
                this.series.setMarkers(this.markers)
            }
        }
    },
    watch: {
        operations: {
            deep: true,
            immediate: true,
            handler(){
                this.apply_markers()
            }
        },
        current_theme: {
            deep: true,
            handler(){
                this.chart.applyOptions(this.options)
                this.series.applyOptions(this.line_options)
            }
        }
    },
    mounted(){
        this.get_list()
    },
    created() {
        window.addEventListener("resize", this.resize_chart);
    },
    destroyed() {
        window.removeEventListener("resize", this.resize_chart);
    },
}
</script>
